.banner-container {
    width: 100%;
    transition: 1s;
    height: var(--bannerHeight);
}

.banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile {
    transition: .5s;
    aspect-ratio: 1/1;
    object-fit: cover;
    position: absolute;
    border-radius: 50%;
    border: solid 1px white;
    height: var(--profileSize);
    left: calc(var(--profileSize) / 4);
    top: calc(var(--bannerHeight) - calc(var(--profileSize) / 2));
}

.profile-container {
    padding: 15px;
    margin-top: calc(var(--profileSize) / 2);
}

.profile-navbar {
    display: flex;
    justify-content: space-between;
}

.nav-widget {
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: .75s;
    background-color: transparent;
}

.nav-widget:hover {
    background-color: rgba(255, 255, 255, .1);
}

.widget-selected {
    width: 100px;
    height: 1px;
    margin-left: 50%;
    position: relative;
    transform: translateX(-50%);
    background-color: var(--underlineColor, blue);
}

.widget-text {
    color: grey;
    padding: 10px;
}

.button-container {
    width: 100%;
    text-align: end;
    position: absolute;
    background-color: transparent;
}
