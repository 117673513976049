.post {
    cursor: pointer;
    transition: .5s;
    background-color: transparent;
}

.post:hover {
    background-color: var(--hoverColor, transparent);
}

.post-container {
    width: 100%;
    height: 100%;
    padding: 10px;
    background-color: transparent;
}

.post-profile {
    transition: .5s;
    object-fit: cover;
    aspect-ratio: 1/1;
    border-radius: 50%;
    height: var(--profilePostSize);
}

.post-wrapper {
    height: 50px;
    display: flex;
    width: 100%;
}

.icon-wrapper {
    width: 100%;
    padding: 10px;
    display: flex;
    gap: 20px;
    justify-content: start;
    background-color: transparent;
}

.widget {
    z-index: 100;
    border: none;
    cursor: pointer;
    position: relative;
    background: transparent;
    margin: 0 var(--iconMargin);
    font-size: var(--mediumTextSize);
    color: var(--contentColor, grey);
}

.widget:hover {
    color: rgb(var(--hoverColor));
}

.widget:hover .icon {
    transition: .5s;
    background: rgba(var(--hoverColor), 0.1373);
    box-shadow: 0 0 0 5px rgba(var(--hoverColor), 0.1373);
}

.icon {
    padding: 5px;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    vertical-align: middle;
    font-size: var(--mediumTextSize);
    transition: background 0.5s ease;
}

.media {
    z-index: 100;
    padding: 5px;
    transition: .5s;
    cursor: pointer;
    object-fit: cover;
    width: var(--postImageSize, 300px);
    height: var(--postImageSize, 300px);
}

.ambient-media {
    z-index: 50;
    width: 100%;
    height: 100%;
    opacity: .25;
    display: block;
    position: absolute;
    filter: blur(100px);
}

.media-container {
    display: flex;
    flex-wrap: wrap;
    transition: .5s;
    margin-left: 50%;
    justify-content: center;
    transform: translateX(-50%);
    background-color: transparent;
    width: var(--postImageContainer);
}

.media-partner0 {
    width: 100%;
    border-radius: var(--mediaBorderRadius);
}

.media-partner1 {
    border-radius: var(--mediaBorderRadius) 0 0 0;
}

.media-partner2 {
    border-radius: 0 var(--mediaBorderRadius) var(--mediaBorderRadius) 0;
}

.media-partner3 {
    border-radius: 0 0 var(--mediaBorderRadius) var(--mediaBorderRadius);
    width: calc(var(--postImageSize) * 2 + 10px);
}

.media-partner4 {
    border-radius: var(--mediaBorderRadius) 0 0 var(--mediaBorderRadius);
}

.media-partner12 {
    border-radius: 0 0 0 var(--mediaBorderRadius);
}

.media-partner32 {
    border-radius: var(--mediaBorderRadius) 0 0 0;
}

.media-partner43 {
    border-radius: 0 var(--mediaBorderRadius) 0 0;
}

.media-partner41 {
    border-radius: 0 0 var(--mediaBorderRadius) 0;
}
