.navigation-wrapper {
    height: 100%;
    position: fixed;
    text-align: end;
    display: var(--sizedDisplay);
    background-color: transparent;
    width: calc(var(--pageSize)/2);
    right: calc(50% + var(--pageSize)/2);
}

.align-content {
    display: inline-block;
    background-color: transparent;
}

.align-content li {
    gap: 20px;
    padding: 10px;
    display: flex;
    cursor: pointer;
    transition: .5s;
    margin: 10px 30px;
    border-radius: 20px;
    align-items: center;
    font-size: calc(var(--largeTextSize) * 1.1);
}

.align-content li:hover {
    background-color: var(--hoverColor);
}

.sidebar-wrapper {
    height: 100%;
    position: fixed;
    margin-top: 10px;
    display: var(--sizedDisplay);
    background-color: transparent;
    width: calc(var(--pageSize)/2);
    left: calc(50% + var(--pageSize)/2);
}

.sidebar-wrapper li {
    margin: 10px;
}

.media-sidebar {
    width: 85%;
    height: 450px;
    padding: 10px;
    margin-top: 20px;
    text-align: center;
    position: relative;
    border-radius: 20px;
    background-color: transparent;
    border: solid 1px;
}

.search-container {
    width: 85%;
    height: 40px;
    padding: 10px;
    display: flex;
    cursor: pointer;
    align-items: center;
    border-radius: 30px;
    background-color: transparent;
    border: solid 1px transparent;
}

.search-body {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 10px;
    color: white;
    border-radius: 20px;
    background-color: transparent;
}

.sidebar-image {
    width: 135px;
    cursor: pointer;
    border-radius: 10px;
}
