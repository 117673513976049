.body {
    overflow: hidden;
}

.background {
    opacity: 1;
    width: 100%;
    z-index: -1;
    position: fixed;
    animation: fadeAnimation 3s;
}

.expanded-media {
    max-height: 80vh;
    border-radius: 10px;
}

.expanded-video {
    z-index: 100;
    width: 175vh;
    max-height: 80vh;
    object-fit: cover;
    position: relative;
    border-radius: 10px;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, .5));
}

.expanded-ambient {
    top: 0;
    left: 0;
    opacity: .5;
    z-index: 50;
    width: 100%;
    height: 100%;
    position: fixed;
    filter: blur(100px);
}

.sidebar-body {
    width: 100%;
    height: 85vh;
    cursor: pointer;
    position: relative;
    align-content: center;
    background-color: transparent;
}

.expanded-wrapper {
    display: flex;
    align-items: center;
}

.sidebar-widget {
    margin: 10px;
    font-size: 3em;
    transition: .5s;
    background-color: transparent;
    filter: drop-shadow(0 0 4px black)
}

.sidebar-widget:hover {
    transform: scale(1.1);
}

.media-scroll {
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    background-color: transparent;
    justify-content: space-evenly;
}

.scroll-text {
    font-size: 100px;
}

.scroll-body {
    width: 100%;
    z-index: -1;
    height: 100%;
    opacity: .5;
    position: fixed;
    background-color: transparent;
}

.media-preview {
    opacity: .5;
    transition: .5s;
    cursor: pointer;
    margin: 0 10px;
    max-width: 125px;
    object-fit: cover;
    aspect-ratio: 1/1;
    border-radius: 10px;
}

.media-preview:hover {
    opacity: 1;
}

.selected-media {
    opacity: 1;
}

.close-body {
    top: 50px;
    left: 50px;
    height: 60px;
    z-index: 100;
    cursor: pointer;
    position: fixed;
    transition: .5s;
    text-align: center;
    aspect-ratio: 1/1;
    border-radius: 50%;
    align-content: center;
    background-color: white;
}

.close-widget {
    font-size: 1.7em;
}

.close-body:hover {
    transform: scale(1.1);
}

.media-icons {
    gap: 2%;
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    background-color: transparent;
}
