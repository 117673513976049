.top-button {
    left: 50px;
    bottom: 50px;
    height: 75px;
    position: fixed;
    transition: .5s;
    cursor: pointer;
    aspect-ratio: 1/1;
    border-radius: 50%;
    text-align: center;
    align-content: center;
    background-color: transparent;
    border: solid 1px;
}

.top-button:hover {
    transform: scale(1.1);
}
