.input-container, select {
    width: 100%;
    height: 20px;
    padding: 5px;
    float: left;
    resize: none;
    border: none;
    outline: none;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    box-sizing: border-box;
    font-size: var(--textFont);
    background-color: transparent;
}

.input-container:focus {
    outline: none;
}

.input-wrapper {
    width: 300px;
    cursor: text;
    height: 60px;
    border: 1px solid;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0);
}

.expanded-input {
    height: 160px;
}

.input-placeholder {
    padding: 10px;
    transition: .15s;
    margin-top: 10px;
    position: absolute;
    font-size: 1.15em;
}

.selected {
    margin-top: 0;
    font-size: 1em;
}

.char-counter {
    padding: 10px;
    text-align: end;
    position: relative;
    background-color: transparent;
}

.image-preview {
    max-width: 200px;
    border-radius: 5px;
}

.composer-container {
    display: flex;
    height: 100px;
}

.composer-profile {
    margin: 10px;
    transition: .5s;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
    height: var(--profilePostSize);
}

.composer-input {
    width: 100%;
    height: 100%;
    padding: 10px;
    font-size: 1.25em;
}

.composer-widgets {
    display: flex;
    margin: 10px;
}