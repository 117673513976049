.card-body {
    min-width: var(--cardWidth);
    margin: 20px;
    padding: 20px;
    height: 100%;
    transition: 1s;
    border: solid 1px;
    border-radius: 10px;
    background-color: black;
}

.title-wrapper {
    display: flex;
    width: 90%;
    margin-left: 50%;
    margin-top: 20px;
    transform: translateX(-50%);
    justify-content: space-between;
}

.timeline-body {
    width: 90%;
    margin: 30px;
    display: flex;
    margin-left: 50%;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
    justify-content: space-between;
    height: var(--educationTimelineSize);
}

.timeline-progress {
    width: 0%;
    position: absolute;
    border-radius: 10px;
    height: var(--educationTimelineSize);
    animation: progressAnimation 2s forwards;
}

.point-container {
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: var(--educationPointSize);
    height: var(--educationPointSize);
}

.timeline-point {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
}

.point-overlay {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    background-color: transparent;
    animation: pingAnimation 2s infinite;
    box-shadow:inset 0px 0px 0px 4px var(--ringColor);
}

.education-progress-body {
    width: 100%;
    margin: 10px;
    margin-left: 50%;
    border-radius: 5px;
    transform: translateX(-50%);
    height: var(--educationTimelineSize);
}

.education-progress {
    border-radius: 5px;
    position: absolute;
    height: var(--educationTimelineSize);
    animation: progressAnimation 2s forwards;
}

@keyframes progressAnimation {
    from { width: 0%; }
    to { width: var(--educationProgress, 100%); }
}

@keyframes pingAnimation {
    from { opacity: 1; transform: scale(1); }
    to { opacity: 0; transform: scale(2.5); }
}
