:root {
    /* Page */
    --pageSize: 950px;
    --cardWidth: 700px;
    --navbarSize: 75px;
    --iconMargin: 20px;

    /* Navigation */
    --navigationSize: 200px;

    /* Text */
    --titleTextSize: 21em;
    --largeTextSize: 26px;
    --mediumTextSize: 24px;
    --smallTextSize: 18px;

    /* Homepage */
    --repoCardWidth: 325px;
    --projectCardWidth: 825px;
    --actionButtonSize: 40px;
    --actionButtonFontSize: 1em;
    --itemMargin: 35px 100px;
    --contributionsWidth: 850px;
    --contributionsEntrySize: 12px;
    --contributionsEntryMargin: 16px;
    --educationPointSize: 30px;
    --educationTimelineSize: 10px;

    /* Profile */
    --bannerHeight: 300px;
    --profileSize: 175px;
    --postImageContainer: 900px;
    --postImageSize: 400px;
    --profilePostSize: 75px;
    --mediaBorderRadius: 20px;
}

@media screen and (max-width: 1150px) {
    :root {
        /* Page */
        --pageSize: 800px;
        --cardWidth: 500px;
        --navbarSize: 45px;

        /* Text */
        --titleTextSize: 14em;
        --largeTextSize: 22px;
        --mediumTextSize: 20px;
        --smallTextSize: 16px;

        /* Homepage */
        --projectCardWidth: 700px;
        --actionButtonSize: 35px;
        --actionButtonFontSize: 0.9em;
        --itemMargin: 30px 80px;
        --contributionsWidth: 700px;
        --contributionsEntrySize: 10px;
        --contributionsEntryMargin: 13px;

        /* Profile */
        --bannerHeight: 250px;
        --profileSize: 125px;
        --postImageContainer: 700px;
        --postImageSize: 300px;
        --profilePostSize: 65px;
        --buttonSize: 120px;

        /* Misc */
        --desktopDisplay: none;
    }
}

@media screen and (max-width: 800px) {
    :root {
        /* Page */
        --pageSize: 600px;
        --cardWidth: 400px;
        --navbarSize: 40px;

        /* Text */
        --titleTextSize: 10em;
        --largeTextSize: 18px;
        --mediumTextSize: 16px;
        --smallTextSize: 14px;

        /* Homepage */
        --repoCardWidth: 500px;
        --projectCardWidth: 500px;
        --actionButtonSize: 30px;
        --actionButtonFontSize: 0.7em;
        --itemMargin: 25px 60px;
        --contributionsWidth: 535px;
        --contributionsEntrySize: 8px;
        --contributionsEntryMargin: 10px;
        --educationPointSize: 25px;
        --educationTimelineSize: 8px;

        /* Profile */
        --bannerHeight: 200px;
        --profileSize: 100px;
        --postImageContainer: 600px;
        --postImageSize: 200px;
        --profilePostSize: 55px;
        --buttonSize: 110px;

        /* Misc */
        --desktopDisplay: none;
    }
}

@media screen and (max-width: 600px) {
    :root {
        /* Page */
        --pageSize: 100%;
        --cardWidth: 300px;

        /* Text */
        --titleTextSize: 8em;
        --largeTextSize: 16px;
        --mediumTextSize: 14px;
        --smallTextSize: 12px;

        /* Homepage */
        --repoCardWidth: 360px;
        --projectCardWidth: 360px;
        --itemMargin: 20px 40px;
        --contributionsWidth: 400px;
        --contributionsEntrySize: 6px;
        --contributionsEntryMargin: 7px;
        --educationPointSize: 20px;

        /* Profile */
        --bannerHeight: 150px;
        --profileSize: 75px;
        --postImageContainer: 400px;
        --postImageSize: 175px;
        --profilePostSize: 45px;
        --buttonSize: 100px;

        /* Misc */
        --desktopDisplay: none;
    }
}

@media screen and (max-width: 430px) {
    :root {
        /* Page */
        --cardWidth: 200px;

        /* Text */
        --titleTextSize: 6em;
        --largeTextSize: 14px;
        --mediumTextSize: 12px;
        --smallTextSize: 10px;

        /* Homepage */
        --repoCardWidth: 300px;
        --projectCardWidth: 300px;
        --itemMargin: 15px 20px;
        --contributionsWidth: 300px;
        --contributionsEntrySize: 4.5px;
        --contributionsEntryMargin: 5.5px;
        --educationPointSize: 15px;
        --educationTimelineSize: 4px;

        /* Profile */
        --bannerHeight: 100px;
        --profileSize: 50px;
        --postImageContainer: 300px;
        --postImageSize: 100px;
        --profilePostSize: 35px;
        --buttonSize: 90px;

        /* Misc */
        --desktopDisplay: none;
    }
}
