.navigation-body {
    right: 0;
    height: 100%;
    z-index: 100;
    position: fixed;
    transition: .5s;
    width: max-content;
    justify-content: end;
    border-right: solid 1px;
    background-color: black;
    width: var(--navigationSize);
    display: var(--desktopDisplay);
}

.navigation-text-wrapper {
    padding: 10px;
    float: right;
    text-align: start;
    width: max-content;
}

.navigation-text-wrapper p {
    margin: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: .25s;
    border-radius: 10px;
}

.navigation-title-container {
    gap: 10px;
    display: flex;
    padding: 10px;
    align-content: center;
}

.navigation-text-wrapper p:hover {
    background-color: var(--hoverColor);
}

.navigation-body-shown {
    left: 0;
}

.navigation-body-hidden {
    left: calc(var(--navigationSize) * -1.25);
}

.navigation-fixed-icon {
    top: 0;
    left: 0;
    margin: 10px;
    z-index: 100;
    position: fixed;
    display: var(--desktopDisplay);
}

.navigation-icon {
    cursor: pointer;
}
