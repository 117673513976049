* {
    margin: 0;
    padding: 0;
    font-family: Helvetica;
}

body {
    margin: 0;
    display: flex;
    min-height: 100vh;
    overflow-x: hidden;
    background: black;
    flex-direction: column;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

a, p, span {
    color: inherit;
    text-decoration: none;
    word-break: break-word;
    overflow-wrap: break-word
}

.darken {
    z-index: 200;
    filter: brightness(50%);
}

.blur {
    filter: blur(5px);
}

.divider {
    position: relative;
    border-bottom: 1px solid;
}

.vertical-center {
    height: 100vh;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: transparent;
}

.item-text {
    transition: .5s;
    padding: var(--itemMargin);
    font-size: var(--largeTextSize);
}

.content-center {
    display: grid;
    justify-content: center;
}

.title-container {
    width: 100%;
    height: 30px;
    padding: 50px 0;
}

.vertical-align {
    align-items: center;
}

.title {
    font-size: calc(var(--largeTextSize) * 2);
}

.large-text {
    font-size: var(--largeTextSize);
}

.medium-text {
    font-size: var(--mediumTextSize);
}

.small-text {
    font-size: var(--smallTextSize);
}

.admin-text {
    padding: 20px;
    font-size: 1.5em;
}

.outlined-view {
    margin: 10px;
    border-radius: 10px;
    border: solid 1px;
}

.admin-component {
    width: 100%;
    height: 100%;
    transition: .5s;
    cursor: pointer;
    border-radius: 10px;
}

.admin-component:hover {
    background-color: var(--hoverColor);
}

.center {
    text-align: center;
    align-items: center;
    align-content: center;
}

.flex-container {
    display: flex;
}

.flex-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.shadow {
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, .35);
}

.white-shadow {
    box-shadow: 0px 0px 6px 3px rgba(255, 255, 255, 0.25);
}

.inline-container {
    position: relative;
    display: inline-block;
    overflow: visible;
}

legend {
    padding: 10px;
    font-size: var(--mediumTextSize);
}

fieldset {
    margin: 10px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
}

.wrapper {
    z-index: -1;
    margin-left: 50%;
    min-height: 100vh;
    transition: width 1s;
    width: var(--pageSize);
    transform: translateX(-50%);
    background-color: transparent;
}

.border {
    border-left: solid 1px;
    border-right: solid 1px;
}

.menu-container {
    left: 50%;
    width: 200px;
    padding: 5px;
    margin: 30px 0;
    font-size: .8em;
    text-align: left;
    position: absolute;
    font-weight: normal;
    border-radius: 10px;
    z-index: 100 !important;
    transform: translateX(-50%);
}

.item-container {
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.outlined-view {
    border-radius: 10px;
    padding: 10px;
    border: 1px solid;
}

.link {
    color: inherit;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

.project-card {
    margin: 5px;
    padding: 10px;
    transition: .5s;
    cursor: pointer;
    border-radius: 10px;
    animation: appearAnimation 2s;
    width: var(--projectCardWidth);
    border: 1px solid;
}

.repo-card {
    margin: 5px;
    padding: 10px;
    transition: .5s;
    cursor: pointer;
    border-radius: 10px;
    width: var(--repoCardWidth);
    animation: appearAnimation 2s;
    border: 1px solid;
}

.repo-card:hover {
    background-color: var(--hoverColor);
}

.project-card:hover {
    background-color: var(--hoverColor);
}

.card-image {
    width: 280px;
}

.card-title {
    font-size: 1.75em;
}

.value-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.action-button {
    width: 40px;
    height: 40px;
    margin: 10px;
    cursor: pointer;
    border-radius: 10px;
    border: solid 1px;
}

.tooltip {
    left: 50%;
    z-index: 100;
    padding: 5px;
    margin: 30px 0;
    min-width: 65px;
    font-size: .8em;
    border-radius: 5px;
    position: absolute;
    font-weight: normal;
    text-align: center;
    transform: translateX(-50%);
    border: 1px solid;
}

.button {
    margin: 10px 20px;
    padding: 15px 25px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 100%;
    transition: .35s;
    outline: none;
    border: none;
    cursor: pointer;
    position: relative;
    width: max-content;
    text-transform: uppercase;
    font-size: var(--smallTextSize);
    color: var(--contentColor, black);
    border: solid 1px var(--buttonHoverColor);
    background-color: var(--buttonColor, black);
}

.button:hover {
    background-color: var(--buttonHoverColor, black);
}

.information-button {
    outline: none;
    transition: 1s;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
    background-color: transparent;
    width: var(--actionButtonSize);
    height: var(--actionButtonSize);
    font-size: var(--actionButtonFontSize);
    border: solid 1px;
}

.pinned-banner {
    top: 0;
    width: 100%;
    height: 30px;
    text-align: center;
    position: relative;
    border-radius: 10px 10px 0 0;
    background-color: rgba(0, 0, 0, .5);
}

.pinned-title {
    font-size: 1.3em;
}

.project-image {
    max-width: 100%;
}

.center-wrapper {
    text-align: center;
}

.sort-by-container {
    margin: 0 auto;
    text-align: center;
    display: inline-block;
}

.underline {    
    height: 1px;
    transition: 0.5s;
    background-color: white;    
    width: calc(var(--largeTextSize) * 2);
}

.loader {
    width: 40px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    margin-bottom: 10px;
    display: inline-block;
    animation: spin .5s linear infinite;
    border: 5px solid var(--secondaryColor, grey);
    border-top: 5px solid var(--primaryColor, blue);
}


.parallax-container {
    width: 100%;
    height: 400px;
    overflow: hidden;
    position: relative;
}

.front-layer,
.back-layer {
    top: 50%;
    left: 50%;
    color: black;
    position: absolute;
    font-size: var(--titleTextSize);
    transform: translate(-50%, -50%);
    -webkit-text-stroke: 2px var(--textColor);
}

.front-layer {
    z-index: 1;
}

.back-layer {
    z-index: 0;
    opacity: 0.6;
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes appearAnimation {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to { opacity: 1 }
}
