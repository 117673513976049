.contribute-entry {
    margin: 2px;
    cursor: pointer;
    width: var(--contributionsEntrySize);
    height: var(--contributionsEntrySize);
}

.contribute-entry:hover {
    opacity: 0.7;
}
 
.contributions-grid {
    display: grid;
    margin: 0 auto;
    max-width: var(--contributionsWidth);
    grid-template-columns: repeat(auto-fill, minmax(var(--contributionsEntryMargin), 1fr));
}

.week-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.year-button {
    width: 100px;
    height: 50px;
    transition: .5s;
    cursor: pointer;
    border-radius: 30px;
    border: solid 1px var(--buttonColor);
    background-color: transparent;
   
}

.year-button:hover {
    background-color: var(--buttonColor);
}

.year-selected {
    background-color: var(--buttonColor);
}

.xlabel-container {
    height: 20px;
    display: flex;
    align-items: center;
    background-color: transparent;
    justify-content: space-between;
    width: var(--contributionsWidth);
}