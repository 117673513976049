.header-wrapper {
    display: flex;
    margin: 0 10px;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px;
}

.text-input {
    outline: none;
    padding: 10px;
    border-radius: 10px;
    background-color: transparent;
    border: solid 1px;
}

.drop-component {
    height: 100px;
    margin: 10px;
    cursor: pointer;
    border-radius: 10px;
    border: dashed 1px grey;
    text-align: center;
    align-content: center;
    background-color: transparent;
}

.draggable-wrapper {
    left: 0;
    top: 80px;
    padding: 10px;
    height: 300px;
    position: fixed;
    text-align: center;
    align-content: center;
    border-radius: 0 20px 20px 0;
    background-color: transparent;
    width: calc(var(--pageSize) / 2.25);
    border-top: solid 1px;
    border-right: solid 1px;
    border-bottom: solid 1px;
}

.draggable-component {
    z-index: 1000;
    margin: 10px;
    height: 80px;
    cursor: pointer;
    aspect-ratio: 2/1;
    text-align: center;
    border-radius: 10px;
    align-content: center;
    border: solid 1px var(--widgetColor);
    background-color: var(--widgetColor);
}

.project-component {
    cursor: pointer;
    transition: .5s;
    background-color: transparent;
}

.project-component:hover {
    background-color: var(--hoverColor);
}

.editor-wrapper {
    right: 0;
    top: 80px;
    padding: 10px;
    height: 200px;
    position: fixed;
    text-align: center;
    align-content: center;
    border-radius: 20px 0 0 20px;
    background-color: transparent;
    width: calc(var(--pageSize) / 2.25);
    border-top: solid 1px;
    border-left: solid 1px;
    border-bottom: solid 1px;
}

.component-image {
    width: 100%
}

.information-container {
    left: 0;
    bottom: 0;
    padding: 10px;
    position: fixed;
    background-color: transparent;
}

.link-button {
    transition: .5s;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 5px;
    background-color: black;
    height: var(--actionButtonSize);
    border: solid 1px;
    font-size: var(--actionButtonFontSize);
    width: calc(var(--actionButtonSize) * 3);
}

.link-button:hover {
    background-color: var(--hoverColor);
}

.tag {
    padding: 3px;
    margin: 0 7px;
    border-radius: 5px;
    display: inline-block;
    background-color: transparent;
    border: solid 1px;
}
