.value-column {
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    border-bottom: solid 1px;
    background-color: transparent;
}

.column-component {
    height: 100%;
    padding: 10px;
    text-align: start;
    border-left: solid 1px;
}

.expanded-column {
    padding: 5px;
}

.column-dropdown {
    height: 100%;
    padding: 10px;
    margin-left: auto;
}

.dashboard-button {
    padding: 5px 10px;
}
