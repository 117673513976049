.menu-container {
    z-index: 1000;
    left: 50%;
    width: 200px;
    padding: 5px;
    transition: .25s;
    margin: 30px 0;
    font-size: .8em;
    text-align: left;
    position: absolute;
    font-weight: normal;
    border-radius: 10px;
    transform: translateX(-50%);
}
